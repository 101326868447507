@tailwind base;
@tailwind components;
@tailwind utilities;

/* ------------- Fonts ------------- */

@font-face {
    font-family: "FrutigerLTCom-BlackItalic";
    src: url("../../public/fonts/frutigerLTCom/FrutigerLTCom-BlackItalic.ttf") format("truetype");
}

@font-face {
    font-family: "FrutigerLTCom-Black";
    src: url("../../public/fonts/frutigerLTCom/FrutigerLTCom-Black.ttf") format("truetype");
}

@font-face {
    font-family: "FrutigerLTCom-Bold";
    src: url("../../public/fonts/frutigerLTCom/FrutigerLTCom-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "FrutigerLTCom-Roman";
    src: url("../../public/fonts/frutigerLTCom/FrutigerLTCom-Roman.ttf") format("truetype");
}

@layer base {
    .user-select-none {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .user-drag-none {
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
    }
}

@layer components {
    .scrollbar-hidden {
        overflow: hidden;
        scrollbar-width: none;
    }

    .scrollbar-hidden::-webkit-scrollbar {
        display: none;
    }
}

@layer utilities {
    .shadow-card {
        display: flex;
        width: 361px;
        padding: 32px 32px 40px 32px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 24px;
        border-radius: 16px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        background: var(--White, #fff);
        box-shadow:
            0px 55px 15px 0px rgba(0, 0, 0, 0),
            0px 35px 14px 0px rgba(0, 0, 0, 0.01),
            0px 20px 12px 0px rgba(0, 0, 0, 0.04),
            0px 9px 9px 0px rgba(0, 0, 0, 0.07),
            0px 2px 5px 0px rgba(0, 0, 0, 0.08);
    }

    .storyScrollbars::-webkit-scrollbar {
        display: none;
    }

    .animatedHeight {
        transition: height 1s ease 0.3s;
    }

    .animatedMargin {
        transition: all 1s ease 0.3s;
    }

    .bouncy-button {
        animation: scaleAnimation 2s 2s infinite ease-in-out;
        animation-delay: 1s;
        animation-fill-mode: both;
    }

    .chatEnter {
        animation: chatEnter 1s ease 0.3s both;
    }

    .chatBubble {
        border-radius: 16px 64px 64px 64px;
        /* border: 4px solid rgba(255, 255, 255, 0.65);
    background: url("/images/bubbleBackground.png"); */
        box-shadow: 0px 0px 50px 0px rgba(31, 85, 97, 0.3);
        backdrop-filter: blur(64px);
    }

    .chatBubbleRight {
        border-radius: 64px 16px 64px 64px;
        /* border: 4px solid rgba(255, 255, 255, 0.65); */
        /* background: url("/images/bubbleBackground.png"); */
        box-shadow: 0px 0px 50px 0px rgba(31, 85, 97, 0.3);
        backdrop-filter: blur(64px);
    }

    .selectionBubble {
        border-radius: 64px;
        background: url("/images/bubbleBackground.png");
        background-blend-mode: multiply, normal;
        box-shadow:
            0px 0px 72px 30px rgba(255, 255, 255, 0.6) inset,
            0px 0px 60px 0px rgba(255, 255, 255, 0.3);
        /* backdrop-filter: blur(36px); */
    }

    .selectionBubbleOnline {
        border-radius: 90px;
        background: url("/images/bubbleBackground.png");
        background-blend-mode: multiply, normal;
        box-shadow:
            0px 0px 16px 6px rgba(255, 255, 255, 0.6) inset,
            0px 0px 13px 0px rgba(255, 255, 255, 0.3);
        /* backdrop-filter: blur(36px); */
    }

    .selectionBubble:active {
        box-shadow:
            0px 0px 72px 30px rgba(255, 255, 255, 1) inset,
            0px 0px 60px 0px rgba(255, 255, 255, 0.3);
        /* backdrop-filter: blur(36px); */
    }

    .selectionBubbleOnline:active {
        box-shadow:
            0px 0px 16px 6px rgba(255, 255, 255, 1) inset,
            0px 0px 13px 0px rgba(255, 255, 255, 0.3);
        /* backdrop-filter: blur(36px); */
    }

    .chatButtonFadeIn {
        opacity: 0.00001;
        /* Set initial opacity to 0 */
        animation: chatButtonFadeInAnimation 1s ease forwards;
        /* Define the animation */
    }

    .chatButtonFadeOut {
        opacity: 1;
        /* Set initial opacity to 0 */
        animation: chatButtonFadeOutAnimation 1s ease forwards;
        /* Define the animation */
    }

    .markdown-container ul li {
        padding-left: 20px;
        text-indent: -10px;
    }

    .markdown-container b,
    .markdown-container strong {
        font-weight: bold;
        color: black;
        text-transform: uppercase;
    }

    .red-link {
        color: #DD0B2F; 
        text-decoration: underline; 
      }
      
      .tel-link {
        color: inherit; 
        text-decoration: none; 
      }
      

    @keyframes chatEnter {
        0% {
            transform: translateX(110%);
            opacity: 0;
        }

        100% {
            transform: translateX(10px);
            opacity: 100;
        }
    }

    @keyframes scaleAnimation {
        0% {
            transform: scaleX(1) scaleY(1);
        }

        30% {
            transform: scaleX(1.15) scaleY(1.15);
        }

        60% {
            transform: scaleX(1) scaleY(1);
        }

        100% {
            transform: scaleX(1) scaleY(1);
        }
    }

    @keyframes chatButtonFadeInAnimation {
        from {
            opacity: 0.00001;
        }

        to {
            opacity: 1;
        }
    }

    @keyframes chatButtonFadeOutAnimation {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
        }
    }
}

:root {
    touch-action: pan-x pan-y;
}

html,
body {
    overscroll-behavior-y: none;
}

/* .storyScrollbars::-webkit-scrollbar {
    cursor: pointer;
    width: 18px;
}


.storyScrollbars::-webkit-scrollbar-track {
    background-color: #23233c;
    border-radius: 999px;
}


.storyScrollbars::-webkit-scrollbar-thumb {
    background-color: #000028;
    border: 4px solid #23233c;
    border-radius: 999px;
}


.storyScrollbars::-webkit-scrollbar-thumb:hover {
    background-color: #00ffb9;
}

.strategyScrollbars::-webkit-scrollbar {
    cursor: pointer;
    width: 4px;
}

.strategyScrollbars::-webkit-scrollbar-track {
    background: #000028;
}

.strategyScrollbars::-webkit-scrollbar-thumb {
    border-radius: 999px;
    background-color: #00cccc;
}

.strategyScrollbars::-webkit-scrollbar-thumb:hover {
    background-color: #00ffb9;
} */

#onetrust-consent-sdk {
    font-family: 'FrutigerLTCom-Roman';
}

#onetrust-pc-sdk .ot-always-active,
#onetrust-consent-sdk #onetrust-pc-sdk .privacy-notice-link,
#onetrust-consent-sdk #onetrust-pc-sdk .ot-pgph-link,
#onetrust-consent-sdk #onetrust-pc-sdk .category-vendors-list-handler,
#onetrust-consent-sdk #onetrust-pc-sdk .category-vendors-list-handler+a,
#onetrust-consent-sdk #onetrust-pc-sdk .category-host-list-handler,
#onetrust-consent-sdk #onetrust-pc-sdk .ot-ven-link,
#onetrust-consent-sdk #onetrust-pc-sdk .ot-ven-legclaim-link,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-host-lst .ot-host-name a,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-host-lst .ot-acc-hdr .ot-host-expand,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-host-lst .ot-host-info a,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content #ot-pc-desc .ot-link-btn,
#onetrust-consent-sdk #onetrust-pc-sdk .ot-vnd-serv .ot-vnd-item .ot-vnd-info a,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-lst-cnt .ot-vnd-info a,
#onetrust-policy-text .ot-cookie-policy-link,
#onetrust-policy-text .ot-imprint-link {
    color: #dc092f !important;
}

#onetrust-pc-sdk .ot-chkbox input:checked~label::before,
#onetrust-pc-sdk #filter-btn-handler {
    background-color: #dc092f !important;
}

#ot-sdk-btn-floating .ot-floating-button__back svg {
    display: inline !important;
}

#ot-sdk-btn-floating .ot-floating-button__front.custom-persistent-icon {
    background-size: 130% !important;
}

#ot-pc-desc>a,
#onetrust-policy-text>a {
    margin-top: 8px !important;
    display: block;
}


#ot-sdk-btn-floating {
    display: none !important;
    visibility: hidden !important;
    pointer-events: none !important;
    opacity: 0 !important;
    width: 0 !important;
    height: 0 !important;
    overflow: hidden !important;
    position: absolute !important;
    top: -9999px !important;
    left: -9999px !important;
}